<template>
  <span class="card-indicator-active"></span>
  <span :class="[indicator > 1 ? 'card-indicator-active' : 'card-indicator']"></span>
  <span :class="[indicator === 3 ? 'card-indicator-active' : 'card-indicator']"></span>
</template>

<script>
export default {
  name: 'CardIndicator',
  props: ['value'],
  data() {
    return {
      indicator: 0,
    };
  },
  created() {
    if (this.value < 40) {
      return this.indicator = 1;
    }

    if (this.value > 70) {
      return this.indicator = 3;
    }

    this.indicator = 2;
  },
};
</script>

<style lang="scss">
.card-indicator-active {
  background-color: $primary;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.card-indicator {
  background-color: $light-grey;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
</style>
