<template>
  <div class="card result-card full-report-card shadow">
    <div class="card-position full-report">
      <p>{{ result.position }}</p>
    </div>
    <div class="card-header">
      <p>
        <span class="card-score">{{ result.score }}%</span> match
      </p>
      <h2 class="card-title">{{ engine.title }}</h2>
      <div class="d-flex">
        <p style="margin-top: 0">{{ engine.sub }}</p>
        <i class="bi bi-caret-down-fill" @click="showCard = !showCard"></i>
      </div>
    </div>
    <div class="card-body" v-if="showCard">
      <el-collapse v-model="activeItem" accordion>
        <el-collapse-item name="1">
          <template #title>
            <p
              class="card-item-header"
              style="line-height: 1.2; padding-bottom: 1rem"
            >
              {{ $t('results.report.title1') }}
            </p>
          </template>
          <p class="">{{ engine.description }}</p>
          <p class="mt-2" v-if="engine.warning">
            <i class="bi bi-exclamation-circle-fill"></i>{{ engine.warning }}
          </p>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template #title>
            <p class="card-item-header">{{ $t('results.report.title2') }}</p>
            <CardIndicator :value="result.envScore" />
          </template>
          <p v-for="envItem in engine.env">{{ envItem }}</p>
          <p class="text-small">{{ $t('results.env.small1') }}</p>
        </el-collapse-item>
        <el-collapse-item name="3">
          <template #title>
            <p class="card-item-header">{{ $t('results.report.title3') }}</p>
            <CardIndicator :value="result.ecoScore" />
          </template>
          <p class="text-bold">{{ $t('results.eco.title1') }}</p>
          <p>{{ engine.eco1 }}</p>
          <p class="text-bold">{{ $t('results.eco.title2') }}</p>
          <p>{{ engine.eco2 }}</p>
          <ul v-if="engine.eco_list">
            <li v-for="item in engine.eco_list" :key="item">
              {{ item }}
            </li>
          </ul>
          <p class="text-small">{{ $t('results.eco.small1') }}</p>
        </el-collapse-item>
        <el-collapse-item name="4">
          <template #title>
            <p class="card-item-header">{{ $t('results.report.title4') }}</p>
            <CardIndicator :value="engine.convenience" />
          </template>
          <p>{{ engine.conv1 }}</p>
          <ul v-if="engine.conv_list">
            <li v-for="item in engine.conv_list" :key="item">{{ item }}</li>
          </ul>
          <p v-if="engine.conv3">{{ engine.conv3 }}</p>
          <p class="text-bold" v-if="engine.conv_video">{{ engine.conv2 }}</p>
          <iframe
            v-if="engine.conv_video"
            :src="engine.conv_video"
            title="DATS 24 Youtube channel"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <a
            :href="engine.conv_url"
            target="_blank"
            class="text-bold card-link mb-2"
            v-if="engine.conv_url"
          >
            {{ engine.conv2 }}</a
          >
          <a
            :href="engine.conv_url2"
            target="_blank"
            class="text-bold card-link"
            v-if="engine.conv_url2"
          >
            {{ engine.conv4 }}</a
          >
          <p class="text-small mt-4">{{ $t('results.conv.small1') }}</p>
        </el-collapse-item>
        <el-collapse-item name="5">
          <template #title>
            <p class="card-item-header">{{ $t('results.report.title5') }}</p>
          </template>
          <ul>
            <li
              v-for="item in engine.bonus_list"
              :key="item"
              :class="{ 'is-num': item.type === 'num' }"
            >
              <template v-if="item.type === 'tip'">
                <span class="text-bold">{{ $t('results.tip') }}</span>
                {{ item.text }}
              </template>
              <template v-if="item.type === 'list' || item.type === 'num'">
                {{ item.text }}
              </template>
              <template v-if="item.type === 'link'">
                <a :href="item.url" target="_blank">{{ item.text }}</a>
              </template>
              <template v-if="item.type === 'inline-link'">
                {{ item.text }}
                <a :href="item.url" target="_blank"
                  >{{ item.text2 }}</a
                > {{ item.text3 }}
              </template>
            </li>
          </ul>
        </el-collapse-item>
        <el-collapse-item name="6">
          <template #title>
            <p class="card-item-header">{{ $t('results.report.title6') }}</p>
          </template>
          <ul>
            <li
              v-for="item in engine.extra_list"
              :key="item"
              :class="{ 'is-num': item.type === 'num' }"
            >
              <template v-if="item.type === 'tip'">
                <span class="text-bold">{{ $t('results.tip') }}</span>
                {{ item.text }}
              </template>
              <template v-if="item.type === 'list' || item.type === 'num'">
                {{ item.text }}
              </template>
              <template v-if="item.type === 'link'">
                <a :href="item.url" target="_blank">{{ item.text }}</a>
              </template>
              <template v-if="item.type === 'inline-link'">
                {{ item.text }}
                <a :href="item.url" target="_blank"
                  >{{ item.text2 }} </a
                >{{ item.text3 }} {{ item.text4 }}
              </template>
            </li>
          </ul>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import CardIndicator from '@/components/results/CardIndicator';
export default {
  name: 'ReportItem',
  props: ['engine', 'result'],
  components: {
    CardIndicator,
  },
  data() {
    return {
      activeItem: '',
      showCard: true,
    };
  },
  created() {
    const mobile = window.matchMedia('(max-width: 600px)');
    const tablet = window.matchMedia(
      '(min-width: 601px) and (max-width: 991px'
    );
    const desktop = window.matchMedia('(min-width: 992px)');
    if (mobile.matches) {
      this.showCard = false;
    }

    if (tablet.matches) {
      if (this.result.position === 1) {
        this.showCard = true;
      } else {
        this.showCard = false;
      }
    }

    if (desktop.matches) {
      this.showCard = true;
    }
  },
};
</script>

<style lang="scss">
.full-report-card {
  .card-header {
    .d-flex {
      align-items: center !important;
      justify-content: space-between;
      .bi-caret-down-fill {
        font-size: 25px;
        cursor: pointer;
      }
    }
  }
  .card-body {
    .card-link {
      text-decoration: underline;
      text-underline-offset: 5px;
      color: $info;
      display: flex;
      align-items: center;
      &:hover {
        color: $primary;
      }
    }
    p {
      font-size: 16px;
      line-height: 1.8;
    }
    p.card-item-header {
      font-size: 18px;
      margin-bottom: 0 !important;
      margin-right: 1rem;
    }
    p.text-small {
      font-size: 12px;
      margin-bottom: 0.5rem;
    }
    li {
      list-style: disc;
    }
    .is-num {
      list-style: none !important;
    }
  }
  .el-collapse {
    border-color: transparent !important;
  }

  .el-collapse-item__header {
    font-size: 18px !important;
    color: $dark-grey !important;
    font-weight: 600 !important;
    font-family: $accent-font !important;
    justify-content: space-between !important;
    height: 3.5rem !important;
  }

  .el-collapse-item__content {
    font-size: 16px !important;
    padding-bottom: 15px !important;
  }

  .el-collapse-item:nth-child(5) {
    .card-item-header {
      line-height: 1.2 !important;
    }
  }

  .el-collapse-item:nth-child(6) {
    .el-collapse-item__header {
      border-bottom: 1px solid transparent !important;
    }
  }
}
</style>
